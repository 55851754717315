import axios from "axios";

export default {
    async getRapportini(userID: string) {
        const data = new FormData();
        data.append("limit", "50");
        data.append("offset", "0");
        data.append("where[]", `rapportini_id IN (SELECT rapportini_id FROM rel_rapportini_users WHERE users_id = ${userID})`);
        data.append("orderby", "rapportini_data");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/rapportini", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response.data;
    },

    async saveRapportino(formData) {
        const response = await axios.post("rest/v1/create/rapportini", formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data.data);
        return response;
    },

    async editRapportino(formData, rapportino_id) {
        const response = await axios.post(`rest/v1/edit/rapportini/${rapportino_id}`, formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data.data);
        return response;
    },

    async salvaServiziSvolti(rapportino, servizi) {
        const formData = new FormData();
        formData.append("rapportino_id", rapportino);
        formData.append("servizi", JSON.stringify(servizi));

        //const response = await axios.post(`http://192.168.11.141/firegui_puma/custom/app/salvaServizi`, formData, {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}custom/app/salvaServizi`, formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
};
