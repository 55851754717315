<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo rapportino</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="creaRapportino()">
                    <ion-list class="fields">
                        <!-- Data -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    <span>Orari appuntamento</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{ dayMonthFormat(appuntamento.appuntamenti_giorno) }}, {{ appuntamento.appuntamenti_ora_inizio }} -
                                    {{ appuntamento.appuntamenti_ora_fine }} - Ore previste:
                                    {{ oreTotaliAppuntamento(appuntamento) }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    <span>Cliente</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{
                                        appuntamento.customers_company
                                            ? appuntamento.customers_company
                                            : `${appuntamento.customers_name} ${appuntamento.customers_last_name}`
                                    }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Progetto / Commessa -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    <span>Commessa</span>
                                </div>
                                <div class="value info_aggiuntive">
                                    {{ appuntamento.projects_name }}
                                </div>
                            </div>
                        </ion-item>

                        <!-- Servizi da svolgere -->
                        <!-- <ion-item v-if="servizi && servizi.length != 0" lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    <span>Servizi da svolgere</span>
                                </div>
                                <div v-for="(servizio, index) in serviziDaFare" :key="index" class="value info_aggiuntive">
                                    {{ servizio.name }}
                                </div>
                            </div>
                        </ion-item> -->

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rapportino.ora_fine"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>

                        <!-- Servizi da svolgere -->
                        <!-- <ion-item v-if="serviziDaFare && serviziDaFare.length > 0" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    <div class="title_servizi_da_svolgere">
                                        Servizi svolti
                                    </div>
                                </div>
                                <div class="value">
                                    <ion-select multiple v-model="rapportino.servizi_svolti">
                                        <ion-select-option v-for="(option, index) in serviziDaFare" :key="index" :value="option.id">
                                            {{ option.name }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <ion-item v-if="serviziDaFare && serviziDaFare.length > 0" lines="none" class="ion-no-padding">
                            <div class="select_all_container">
                                <div class="select_all" @click="selectAllServices()">Seleziona tutti i servizi come svolti</div>
                            </div>
                        </ion-item> -->

                        <!-- <ion-item>
                            {{ serviziSelezionati }}
                        </ion-item> -->
                        <!-- SERVIZI DA SVOLGERE -->
                        <ion-item v-if="serviziDaFare && serviziDaFare.length > 0" lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    <div class="title_servizi_da_svolgere">
                                        Servizi svolti
                                    </div>
                                </div>
                                <div class="value">
                                    <div v-for="(servizio, index) in serviziDaFare" :key="index" :value="servizio.id">
                                        <div>{{ servizio.name }}</div>
                                        <div class="radio_group_container">
                                            <ion-radio-group
                                                :value="getServizioValue(servizio.id)"
                                                @ionChange="aggiornaServiziSelezionati(servizio.id, $event)"
                                            >
                                                <ion-item lines="none" class="ion-no-padding item_servizi">
                                                    <ion-label class="label_servizi">Sì</ion-label>
                                                    <ion-radio slot="start" :value="1" class="radio_servizi"></ion-radio>
                                                </ion-item>
                                                <ion-item lines="none" class="ion-no-padding item_servizi">
                                                    <ion-label class="label_servizi">No</ion-label>
                                                    <ion-radio slot="start" :value="0" class="radio_servizi"></ion-radio>
                                                </ion-item>
                                            </ion-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ion-item>
                        <!-- Tecnici -->
                        <!-- <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Operatori
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select name="tecnici" multiple="true" cancel-text="Annulla" ok-text="Conferma" v-model="rapportino.operatori">
                                        <ion-select-option v-for="operatore in operatori" :key="operatore.users_id" :value="operatore.users_id">
                                            {{ `${operatore.users_first_name} ${operatore.users_last_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item> -->

                        <!-- N° stanze -->
                        <ion-item
                            v-if="appuntamento.projects_commessa_a_stanze && appuntamento.projects_commessa_a_stanze === '1'"
                            lines="none"
                            class="ion-no-padding"
                        >
                            <div class="field">
                                <div class="field_title">
                                    N° stanze effettuate
                                </div>
                                <div class="value">
                                    <ion-input
                                        type="number"
                                        v-model="rapportino.n_stanze"
                                        @ionChange="calculateOraFine($event.target.value)"
                                        class="custom_input"
                                    >
                                    </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Note
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="rapportino.note"
                                        placeholder="Indicare qui eventuali servizi non svolti o motivare gli straordinari"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <div v-if="sondaggio" class="checklist_container">
                            <div v-for="(step, i) in sondaggio" :key="i" class="single_step">
                                <div class="single_step_title">{{ step.step }}</div>
                                <div v-for="(domanda, j) in step.domande" :key="j" class="single_field">
                                    <div class="domanda_title">{{ domanda.sondaggi_domande_domanda }}</div>

                                    <!-- Input type (risposta breve) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta breve'">
                                        <ion-input type="text" v-model="formFields[domanda.sondaggi_domande_id]"> </ion-input>
                                    </div>

                                    <!-- Textarea (risposta paragrafo) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Paragrafo'">
                                        <ion-textarea v-model="formFields[domanda.sondaggi_domande_id]" type="text" rows="4"> </ion-textarea>
                                    </div>

                                    <!-- Select (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola'">
                                        <ion-select
                                            interface="action-sheet"
                                            cancel-text="Annulla"
                                            ok-text="Conferma"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ `${option.sondaggi_domande_risposte_risposta}` }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>

                                    <!-- Radio (risposta singola) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta singola - radio'">
                                        <ion-radio-group
                                            :value="domanda.risposte[0].sondaggi_domande_risposte_id"
                                            @ionChange="setRadioRisposta($event, domanda.sondaggi_domande_id)"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                        >
                                            <ion-item
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                lines="none"
                                                class="ion-no-padding ion-no-margin"
                                                style="height: 40px;"
                                            >
                                                <ion-label class="ion-no-padding ion-no-margin">{{ `${option.sondaggi_domande_risposte_risposta}` }}</ion-label>
                                                <ion-radio
                                                    slot="start"
                                                    :value="option.sondaggi_domande_risposte_id"
                                                    class="ion-no-padding ion-no-margin ion-margin-end"
                                                ></ion-radio>
                                            </ion-item>
                                        </ion-radio-group>
                                    </div>

                                    <!-- Select (scelta multipla) -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Risposta multipla - checkbox'">
                                        <ion-select multiple v-model="formFields[domanda.sondaggi_domande_id]">
                                            <ion-select-option
                                                v-for="(option, index) in domanda.risposte"
                                                :key="index"
                                                :value="option.sondaggi_domande_risposte_id"
                                            >
                                                {{ option.sondaggi_domande_risposte_risposta }}
                                            </ion-select-option>
                                        </ion-select>
                                    </div>

                                    <!-- Data -->
                                    <div v-if="domanda.sondaggi_domande_tipologia_value === 'Data'">
                                        <ion-datetime
                                            name="start_time"
                                            display-format="DD/MM/YYYY"
                                            picker-format="DD/MM/YYYY"
                                            min="2024"
                                            max="2099"
                                            slot="end"
                                            doneText="OK"
                                            cancelText="Annulla"
                                            v-model="formFields[domanda.sondaggi_domande_id]"
                                            placeholder="Data"
                                        ></ion-datetime>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Immagini rapportino -->
                        <ion-item lines="none" class="ion-no-padding" v-if="rapportino.immagini.length != 0">
                            <div class="field">
                                <div class="field_title">Immagini</div>
                                <div class="foto_container">
                                    <div v-for="(foto, index) in rapportino.immagini" :key="index" class="single_foto">
                                        <ion-thumbnail>
                                            <img :src="`data:image/png;base64,${foto.data}`" />
                                        </ion-thumbnail>
                                        <div @click="deletePhoto(foto.name)" class="remove_photo">Rimuovi</div>
                                    </div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="action">
                            <div class="btn_allega_foto" @click="openModalPhotos()">Carica foto</div>
                            <button type="submit" class="btn_crea_intervento" :disabled="savingRapportino">
                                Salva rapportino
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonInput,
    IonText,
    IonThumbnail,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonRadioGroup,
    IonLabel,
    IonRadio,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, toRefs, onMounted } from "vue";

import moment from "moment";

import { openToast } from "@/services/toast";
import { dayMonthFormat } from "@/services/utils";
import Loading from "@/services/loading";

import apiRapportini from "@/services/rapportini";
import apiRapportiniCustom from "@/custom/services/rapportini";
import apiSondaggi from "@/services/sondaggi";

import ImagesModal from "@/components/AllegatiRapportino";

export default defineComponent({
    name: "NuovoRapportinoAppuntamento",
    props: {
        data: {
            type: Object,
        },
        services: {
            type: Array,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonInput,
        IonText,
        IonThumbnail,
        IonIcon,
        IonSelect,
        IonSelectOption,
        IonRadioGroup,
        IonLabel,
        IonRadio,
    },
    setup(props, context) {
        const appuntamento = { ...props.data };
        const { services: serviziDaFare } = toRefs(props);

        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const today = moment().format("YYYY-MM-DD");

        //Ora di inizio e fine impostata agli orari dell'appuntamento
        const start_hour = appuntamento.appuntamenti_ora_inizio
            ? moment(`${today} ${appuntamento.appuntamenti_ora_inizio}`).format("YYYY-MM-DD HH:mm")
            : moment().format("YYYY-MM-DD HH:mm");
        const end_hour = appuntamento.appuntamenti_ora_fine
            ? moment(`${today} ${appuntamento.appuntamenti_ora_fine}`).format("YYYY-MM-DD HH:mm")
            : moment().format("YYYY-MM-DD HH:mm");

        const rapportino = reactive({
            cliente: appuntamento.appuntamenti_cliente,
            appuntamento: appuntamento.appuntamenti_id,
            commessa: appuntamento.appuntamenti_impianto,
            data: moment(appuntamento.appuntamenti_giorno).format("YYYY-MM-DD"),
            ora_inizio: moment(start_hour).toISOString(),
            ora_fine: moment(end_hour).toISOString(),
            operatori: [userID],
            note: "",
            immagini: [],
            n_stanze: null,
            servizi_svolti: [],
        });

        // Flag chiusura modale
        const successResponse = ref(false);

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Gestione foto rapportino
         */
        const immaginiRapportino = ref([]);
        async function openModalPhotos() {
            const modal = await modalController.create({
                component: ImagesModal, //Component to render inside ionic modal
                componentProps: {
                    images: immaginiRapportino.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            rapportino.immagini.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }

        /**
         * ! Remove photo from the fotoInterventi reference data array
         */
        function deletePhoto(photo) {
            rapportino.immagini = rapportino.immagini.filter((immagine) => immagine.name !== photo);
        }

        /**
         * ! Cambia l'ora di fine rapportino in base al n° stanze effettuate * tempo previsto per stanza
         * ! da aggiungere all'ora di inizio preimpostata dall'appuntamento
         */
        const originalOraInizio = moment(start_hour).toISOString();
        function calculateOraFine(nStanze) {
            if (appuntamento.projects_commessa_a_stanze == "1" && appuntamento.projects_tempo_prev_stanza > 0 && nStanze && nStanze > 0) {
                const tempoPrevistoStanza = appuntamento.projects_tempo_prev_stanza;
                // calcolo tempo totale e relativo valore in minuti
                const tempoPrevistoTot = parseFloat(nStanze * tempoPrevistoStanza);
                const tempoPrevistoTotMinuti = tempoPrevistoTot * 60;

                rapportino.ora_fine = moment(originalOraInizio)
                    .add(tempoPrevistoTotMinuti, "minutes")
                    .toISOString();
            } else {
                // Imposto nuovamente ora fine rapportino uguale a quella dell'appuntamento
                rapportino.ora_inizio = moment(start_hour).toISOString();
                rapportino.ora_fine = moment(end_hour).toISOString();
            }
        }

        /**
         *
         * ! Gestione servizi da svolgere
         *
         */
        const serviziSelezionati = ref([]);
        const getServizioValue = (id) => {
            const servizio = serviziSelezionati.value.find((s) => s.id === id);
            return servizio ? servizio.value : null;
        };

        const aggiornaServiziSelezionati = (id, event) => {
            const value = event.detail.value;
            const servizioIndex = serviziSelezionati.value.findIndex((s) => s.id === id);

            if (servizioIndex !== -1) {
                serviziSelezionati.value[servizioIndex].value = value;
            } else {
                serviziSelezionati.value.push({ id, value });
            }
        };

        /**
         * ! Contenitore v-model del form
         * */
        const formFields = ref({});
        /**
         * ! Set risposta value based on selected radio
         */
        function setRadioRisposta(event, reference) {
            const value = event.target.value;
            formFields.value[reference] = value;
        }
        /**
         * ! Set risposta value based on selected checkbox
         * ? Devo costruirmi un array con le risposte
         */
        let risposteCheckbox = [];
        function setCheckboxRisposta(event, reference) {
            const value = event.target.value;
            //Se sto deselezionando tolgo risposta dall'array
            if (risposteCheckbox.includes(value)) {
                const tmp = risposteCheckbox.filter((el) => el == value);
                risposteCheckbox = tmp;
            } else {
                //Sto aggiungendo
                risposteCheckbox.push(value);
            }

            formFields.value[reference] = [...risposteCheckbox];
        }

        /*********************************************************
         *
         * ! Crea sondaggio da associare al rapporitno
         *
         *********************************************************/
        const savingRapportino = ref(false);
        const sondaggio = ref(null);

        async function createSondaggio() {
            const response = {
                data: null,
                success: false,
            };

            if (sondaggio.value && Object.keys(formFields.value).length === 0) {
                savingRapportino.value = false;
                openToast("Non è stata fornita alcuna risposta alla checklist", "toast_danger");
                return response;
            }

            //Se ho un sondaggio e almeno una risposta provo a crearlo
            if (sondaggio.value) {
                // Prendo le referenze alle proprietà dell'oggetto reattivo
                const refs = toRefs(formFields.value);
                //Mi costruisco nuovo oggetto nell formato corretto
                const formattedObject = {};
                for (const key in refs) {
                    formattedObject[`risposta[${key}]`] = refs[key].value;
                }

                //Mi creo l'oggetto FormData
                const formData = new FormData();

                for (const key in refs) {
                    let value = refs[key].value;

                    // Check if the value is a date and format it using Moment.js
                    if (value && moment(value, moment.ISO_8601, true).isValid()) {
                        value = moment(value).format("DD/MM/YYYY");
                    }
                    // If the value is an array (multiple select), iterate through it
                    if (Array.isArray(value)) {
                        for (const selectedValue of value) {
                            formData.append(`risposta[${key}][]`, selectedValue);
                        }
                    } else {
                        // If the value is not an array, add it directly
                        formData.append(`risposta[${key}]`, value);
                    }
                }
                /* const formData = new FormData();
                for (const key in formattedObject) {
                    formData.append(key, formattedObject[key]);
                }

                for (const key in refs) {
                    // Se il valore è un array (selezione multipla), itera attraverso di esso
                    if (Array.isArray(refs[key].value)) {
                        for (const selectedValue of refs[key].value) {
                            formData.append(`risposta[${key}][]`, selectedValue);
                        }
                    } else {
                        // Se il valore non è un array, aggiungilo direttamente
                        formData.append(`risposta[${key}]`, refs[key].value);
                    }
                } */

                formData.append("user_id", userID);
                formData.append("sondaggio_id", sondaggio.value[0].sondaggio_id);
                formData.append("sondaggio_da_app", 1);

                /*  formData.forEach((value, key) => {
                    console.log(`${key}: ${value}`);
                });
                return; */

                try {
                    const res = await apiSondaggi.saveCompilazione(formData);

                    if (res.data.status == 0) {
                        openToast(`${res.data.txt}`, "toast_danger");
                        response.success = false;
                        return response;
                    } else if (res.data.status == 1) {
                        response.data = res.data;
                        response.success = true;
                        return response;
                    }
                } catch (error) {
                    console.error(error);
                    openToast("Errore durante il salvataggio della checklist", "toast_danger");
                    return response;
                } finally {
                    savingRapportino.value = false;
                }
            }
        }

        /**
         * ! Reset dati form dopo submit
         */
        function resetFormData() {
            rapportino.ora_inizio = moment(start_hour).toISOString();
            rapportino.ora_fine = moment(end_hour).toISOString();
            rapportino.operatori = [userID];
            rapportino.note = "";
            rapportino.immagini = [];
        }

        /**************************************
         *
         * ! Create new rapportino
         *
         ****************************************/
        async function creaRapportino() {
            savingRapportino.value = true;
            //Controllo che ci sia almeno un operatore selezioanto
            if (rapportino.operatori.length === 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza selezionare gli operatori", "toast_danger");
                return;
            }
            //Controllo di essere sempre tra gli operatori selezionati
            const isUSerSelected = rapportino.operatori.includes(userID);
            if (!isUSerSelected && rapportino.operatori.length != 0) {
                savingRapportino.value = false;
                openToast("Non puoi creare il rapportino senza essere tra gli operatori selezionati", "toast_danger");
                return;
            }
            //Controllo che ora inizio non sia successiva ad ora fine
            const ora_inizio = new Date(rapportino.ora_inizio);
            const ora_fine = new Date(rapportino.ora_fine);
            if (ora_inizio > ora_fine) {
                savingRapportino.value = false;
                openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
                return;
            }

            const ora_inizio_formatted = moment(rapportino.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(rapportino.ora_fine).format("HH:mm");

            const data = new FormData();
            data.append("rapportini_cliente", rapportino.cliente);
            data.append("rapportini_commessa", rapportino.commessa);
            data.append("rapportini_appuntamento_id", rapportino.appuntamento);
            data.append("rapportini_data", rapportino.data);
            data.append("rapportini_ora_inizio", ora_inizio_formatted);
            data.append("rapportini_ora_fine", ora_fine_formatted);
            rapportino.operatori.forEach((tecnico) => {
                data.append("rapportini_operatori[]", tecnico);
            });

            // Servizi svolti tra quelli da fare
            if (serviziDaFare.value && serviziDaFare.value.length > 0 && serviziSelezionati.value.length === 0) {
                savingRapportino.value = false;
                openToast("Non puoi proseguire senza indicare i servizi svolti", "toast_danger");
                return;
            }
            // Note aggiunte al rapportino
            if (rapportino.note) {
                data.append("rapportini_note", rapportino.note);
            }
            // Immagini caricate
            if (rapportino.immagini.length != 0) {
                rapportino.immagini.forEach((foto) => {
                    data.append("rapportini_immagini[]", foto.data);
                });
            }
            // Se la commessa è a stanze devo richiedere l'inserimento e controllare validità del numero di stanze
            if (
                appuntamento.projects_commessa_a_stanze &&
                appuntamento.projects_commessa_a_stanze === "1" &&
                (!rapportino.n_stanze || rapportino.n_stanze <= 0)
            ) {
                savingRapportino.value = false;
                openToast("L'indicazione del numero di stanze effettuate è obbligatoria", "toast_danger");
                return;
            }

            if (rapportino.n_stanze) {
                if (rapportino.n_stanze <= 0) {
                    savingRapportino.value = false;
                    openToast("Il numero di stanze effettuate deve essere maggiore di zero", "toast_danger");
                    return;
                } else {
                    data.append("rapportini_n_stanze", rapportino.n_stanze);
                }
            }

            /* for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return; */

            // Se ho sondaggio procedo alla sua creazione
            if (sondaggio.value) {
                const newSondaggio = await createSondaggio();

                //Creazione sondaggio fallita, blocco esecuzione
                if ((newSondaggio && !newSondaggio.data) || !newSondaggio.success) {
                    openToast("Si è verificato un errore durante il salvataggio della checklist. Contattare l'assistenza", "toast_danger");
                    Loading.dismissLoader();
                    return;
                }

                if (newSondaggio && newSondaggio.data && newSondaggio.success) {
                    const compilazione_id = newSondaggio.data.compilazione_id;
                    data.append("rapportini_compilazione_id", compilazione_id);

                    // Creazione rapportino con associazione del sondaggio appena creato
                    try {
                        Loading.simpleLoader("Salvataggio rapportino in corso", 5000, true);

                        const response = await apiRapportini.saveRapportino(data);

                        if (response.data.status === 8) {
                            openToast(response.data.message, "toast_danger");
                        } else {
                            const res = response.data.data[0];
                            // Procedo con inserimento servizi svolti
                            if (serviziDaFare.value && serviziDaFare.value.length > 0) {
                                const responseServizi = await apiRapportiniCustom.salvaServiziSvolti(res.rapportini_id, serviziSelezionati.value);

                                if (responseServizi.status === 200 && responseServizi.data.status === 1) {
                                    //Svuoto dati form
                                    resetFormData();
                                    //Svuoto dai sondaggio
                                    Object.assign(formFields, {});
                                    savingRapportino.value = false;
                                    successResponse.value = true;
                                    Loading.dismissLoader();
                                    closeModalOnSubmit(successResponse, res);
                                } else {
                                    openToast("Si è verificato un errore durante il salvataggio dei servizi", "toast_danger");
                                }
                            } else {
                                //Svuoto dati form
                                resetFormData();
                                //Svuoto dai sondaggio
                                Object.assign(formFields, {});
                                savingRapportino.value = false;
                                successResponse.value = true;
                                Loading.dismissLoader();
                                closeModalOnSubmit(successResponse, res);
                            }
                        }
                    } catch (error) {
                        console.error(error);
                        savingRapportino.value = false;
                        openToast("Si è verificato un errore durante la creazione del rapportino", "toast_danger");
                    } finally {
                        Loading.dismissLoader();
                    }
                }
            } else {
                // Creazione rapportino senza sondaggio
                Loading.simpleLoader("Salvataggio rapportino in corso", 5000, true);

                try {
                    const response = await apiRapportini.saveRapportino(data);
                    if (response.data.status === 8) {
                        openToast(response.data.message, "toast_danger");
                    } else {
                        const res = response.data.data[0];

                        // Procedo con inserimento servizi svolti
                        if (serviziDaFare.value && serviziDaFare.value.length > 0) {
                            const responseServizi = await apiRapportiniCustom.salvaServiziSvolti(res.rapportini_id, serviziSelezionati.value);

                            if (responseServizi.status === 200 && responseServizi.data.status === 1) {
                                successResponse.value = true;
                                Loading.dismissLoader();
                                closeModalOnSubmit(successResponse, res);
                            } else {
                                openToast("Si è verificato un errore durante il salvataggio dei servizi", "toast_danger");
                            }
                        } else {
                            successResponse.value = true;
                            Loading.dismissLoader();
                            closeModalOnSubmit(successResponse, res);
                        }
                        // successResponse.value = true;
                        // Loading.dismissLoader();
                        // closeModalOnSubmit(successResponse, res);
                    }
                } catch (error) {
                    console.error(error);
                    Loading.dismissLoader();
                    savingRapportino.value = false;
                    openToast("Si è verificato un errore durante la creazione del rapportino", "toast_danger");
                } finally {
                    Loading.dismissLoader();
                    // setTimeout(() => {
                    //     savingRapportino.value = false;
                    // }, 1000);
                }
            }
        }

        function oreTotaliAppuntamento(appuntamento) {
            if (appuntamento.appuntamenti_ora_inizio && appuntamento.appuntamenti_ora_fine) {
                const data = moment().format("YYYY-MM-DD");
                const ora_inizio = moment(`${data} ${appuntamento.appuntamenti_ora_inizio}`);
                const ora_fine = moment(`${data} ${appuntamento.appuntamenti_ora_fine}`);
                const duration = moment.duration(ora_fine.diff(ora_inizio));
                //return moment.duration(ora_fine.diff(ora_inizio));
                return duration.hours() + "." + duration.minutes();
            } else {
                return "-";
            }
        }

        const loadingServizi = ref(false);
        const servizi = ref([]);
        /*async function loadServiziCommessa() {
            loadingServizi.value = true;
            try {
                const res = await apiServiziCommessa.getCommessaServizi(appuntamento.appuntamenti_impianto);
                if (res.status === 0) {
                    //console.log(res);
                    servizi.value = res.data.projects_services;
                } else {
                    openToast("Errore durante la richiesta dei servizi relativi alla commessa", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei servizi relativi alla commessa", "toast_danger");
            } finally {
                //console.log(customers.value);
                loadingServizi.value = false;
            }
        }*/

        // const serviziSelezionati = ref({});
        /* const aggiornaServiziSelezionati = (id, event) => {
            const value = event.detail.value;
            if (value === 1) {
                serviziSelezionati.value[id] = value;
            } else {
                delete serviziSelezionati.value[id];
            }
        }; */

        function selectAllServices() {
            rapportino.servizi_svolti = serviziDaFare.value.map((servizio) => servizio.id);
        }

        /**
         * ! Ricerca sondaggio associato all'appuntamento
         */
        const loadingSondaggio = ref(false);
        async function loadSondaggio(appuntamento_id) {
            loadingSondaggio.value = true;
            //Disabilito button per evitare creazione rapportino se la richiesta della checklist ci mette troppo
            savingRapportino.value = true;

            try {
                const res = await apiSondaggi.getSondaggioAppuntamento(appuntamento_id);
                if (res.data.status === 1) {
                    sondaggio.value = res.data.data;
                } else {
                    openToast(`Checklist non riconosciuta. Contattare l'assistenza`, "toast_danger");
                    //openToast(`${res.data.txt}`, "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta della checklist", "toast_danger");
            } finally {
                loadingSondaggio.value = false;
                savingRapportino.value = false;
            }
        }

        onMounted(() => {
            //loadServiziCommessa();
            //Ricerca sondaggio per appuntamento selezionato
            if (appuntamento.appuntamenti_checklist) {
                loadSondaggio(appuntamento.appuntamenti_checklist);
            }
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            creaRapportino,
            savingRapportino,
            rapportino,
            // operatori,
            appuntamento,
            dayMonthFormat,
            // Gestione immagini
            openModalPhotos,
            deletePhoto,
            oreTotaliAppuntamento,
            // servizi
            servizi,
            loadingServizi,
            calculateOraFine,
            // Select multipla servizi
            serviziDaFare,
            selectAllServices,
            // Servizi si/no
            serviziSelezionati,
            aggiornaServiziSelezionati,
            getServizioValue,
            // Sondaggio
            formFields,
            sondaggio,
            setRadioRisposta,
            // saveRapportino, --> da controllare se serve
            setCheckboxRisposta,
            loadingSondaggio,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f8fafc;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 6px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
ion-button {
    --color: #ffffff;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: #086fa3;
    color: #ffffff;
}
.btn_crea_intervento:disabled {
    opacity: 0.5;
    touch-action: none;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    color: #ffffff;
    background-color: #06b6d4;
    margin: 24px 0;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}
.flex_container .field {
    width: 47%;
}

.info_aggiuntive {
    font-size: 14px;
}

div.alert-wrapper {
    width: 100%;
}
div.alert-checkbox-label {
    font-size: 12px;
}

.title_servizi_da_svolgere {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
div.select_all_container {
    width: 100%;
    display: flex;
    justify-content: center;
}
.select_all {
    font-size: 12px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    padding: 4px;
    font-weight: 500;
}

.radio_group_container ion-radio-group:last-of-type {
    margin-bottom: 0px;
}
ion-radio-group {
    display: flex;
    justify-content: flex-start;
    gap: 24px;

    margin-bottom: 12px;
}
ion-item.item_servizi {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
}
.radio_servizi {
    margin: 0px;
    margin-right: 8px;
}
.label_servizi {
    margin: 0;
}

/******************************** 
*
*          Checklist
*
********************************/
.checklist_container {
    margin-top: 32px;
}
.single_step {
    margin-bottom: 32px;
}
.single_step .single_step_title {
    font-weight: bold;
    margin-bottom: 8px;
}
.single_step .single_field {
    margin-bottom: 16px;
}
.single_step .single_field .domanda_title {
    margin-bottom: 4px;
}
</style>
